import React, { useEffect, useState, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PlaidLinkButton = ({ onStatusChange, userId }) => {
    const [linkToken, setLinkToken] = useState(null);
    const navigate = useNavigate();

    // Define endpoints
    const API_ENDPOINT_DEV = 'http://localhost:3001';
    const API_ENDPOINT_PROD = 'https://orbitonserver.onrender.com';

    // Manually set the active endpoint here
    const API_ENDPOINT = API_ENDPOINT_PROD; // Change this to API_ENDPOINT_PROD for production

    useEffect(() => {
        const fetchLinkToken = async () => {
            try {
                const response = await fetch(`${API_ENDPOINT}/api/create_link_token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setLinkToken(data.link_token);
            } catch (error) {
                console.error('Failed to fetch link token:', error);
            }
        };

        fetchLinkToken();
    }, [API_ENDPOINT]);

    const fetchInvestmentData = async (public_token) => {
        try {
            const exchangeResponse = await fetch(`${API_ENDPOINT}/api/exchange_public_token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ public_token }),
            });
            const exchangeData = await exchangeResponse.json();
            if (!exchangeResponse.ok) {
                throw new Error(`Exchange failed: ${exchangeData.message}`);
            }

            const accessToken = exchangeData.access_token;
            const holdingsResponse = await fetch(`${API_ENDPOINT}/api/investment_holdings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ accessToken }),
            });
            const holdingsData = await holdingsResponse.json();
            if (!holdingsResponse.ok) {
                throw new Error(`Holdings fetch failed: ${holdingsData.message}`);
            }

            // Call the backend to store the investment data
            await storeInvestmentData(userId, holdingsData);

            onStatusChange("Connected - Investments Fetched", holdingsData);
        } catch (error) {
            console.error('Error fetching investments:', error);
            onStatusChange("Disconnected", { error: error.message });
        }
    };

    const storeInvestmentData = async (userId, investments) => {
        try {
            const response = await fetch(`${API_ENDPOINT}/api/store_investments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId, investments })
            });
            const data = await response.json();
            console.log('Investments stored:', data);
        } catch (error) {
            console.error('Failed to store investment data:', error);
        }
    };

    const onSuccess = useCallback((public_token, metadata) => {
        console.log('Plaid Link success:', public_token, metadata);
        fetchInvestmentData(public_token);
        navigate('/shareholder');
    }, [onStatusChange, navigate]);

    const onExit = useCallback((error, metadata) => {
        console.error('Plaid Link exit or error:', error, metadata);
        onStatusChange("Disconnected", metadata);
    }, [onStatusChange]);

    const config = {
        token: linkToken,
        onSuccess,
        onExit,
    };

    const { open, ready, error } = usePlaidLink(config);

    return (
        <Button variant='outlined' onClick={() => ready && open()} disabled={!ready || error}>
            Connect a Bank Account
        </Button>
    );
};

export default PlaidLinkButton;