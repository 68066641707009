import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://lqrzrtcdfxamcnfsbowt.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxxcnpydGNkZnhhbWNuZnNib3d0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwOTkwMzUsImV4cCI6MjAzMDY3NTAzNX0.BKWzGB-WH0oXb1ylNJz_vRYY8yNmTAVIxQtyM5sBaeQ';

let supabase;

try {
  supabase = createClient(supabaseUrl, supabaseAnonKey);
  console.log('Supabase client created successfully');
} catch (error) {
  console.error('Error creating Supabase client:', error);
}

export { supabase };