import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './components/SignUp';  
import WelcomePage from './pages/WelcomePage';  
import { supabase } from './util/supabaseClient';  
import ShareholderPage from './pages/ShareholderPage';  // Ensure the path is correct
import './App.css';


function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    async function checkUser() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error retrieving user:', error);
      } else if (user) {
        setSession(user);
      }
    }

    checkUser();

    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        setSession(session.user);
      } else {
        setSession(null);
      }
    });

    return () => {
      if (data && data.unsubscribe) {
        data.unsubscribe();
      }
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={session ? <Navigate replace to="/welcome" /> : <SignUp />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/shareholder" element={<ShareholderPage />} />
    </Routes>
  );
}

export default App;
