import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#e53935',
    },
    background: {
      default: '#fff',
    },
  },
  // You can add more customization here such as typography, spacing, etc.
});

export default theme;
