import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../util/supabaseClient'; // Ensure the path to your Supabase client is correct
// import OrbitonIcon from '..//orbitonIcon';

const Navbar = () => {
    const navigate = useNavigate();

    // Function to handle user sign out
    const handleSignOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error);
        } else {
            navigate('/');
        }
    };

    // check if the user is signed in:
    const [session, setSession] = useState(null);

    useEffect(() => {
        async function checkUser() {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
            console.error('Error retrieving user:', error);
        } else if (user) {
            setSession(user);
        }
        }

        checkUser();

        const { data } = supabase.auth.onAuthStateChange((_event, session) => {
        if (session) {
            setSession(session.user);
        } else {
            setSession(null);
        }
        });

        return () => {
        if (data && data.unsubscribe) {
            data.unsubscribe();
        }
        };
    }, []);
    
    return (
        <AppBar position="static" color="default" elevation={1} style={{ width: '100%' , marginBottom: '32px'}}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu">
                    {/* <MenuIcon /> */}
                </IconButton>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Orbiton
                </Typography>
                {session && (
                    <Button onClick={handleSignOut} color="inherit">
                        Sign Out
                    </Button>
                )}
                {/* <Button color="inherit" onClick={() => navigate('/login')}>
                    Login
                </Button> */}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
