import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react';
import { createClient } from '@supabase/supabase-js';
import { Container, Typography, Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import Navbar from './NavBar';
import { supabase } from '../util/supabaseClient';  // Import the supabase client

const customTheme = {
  default: {
    colors: {
      brand: 'hsl(153 60.0% 53.0%)',
      brandAccent: 'hsl(154 54.8% 45.1%)',
      brandButtonText: 'white',
      // ..
    },
  },
  dark: {
    colors: {
      brandButtonText: 'white',
      defaultButtonBackground: '#2e2e2e',
      defaultButtonBackgroundHover: '#3e3e3e',
      //..
    },
  },
  evenDarker: {
    colors: {
      brandButtonText: 'white',
      defaultButtonBackground: '#1e1e1e',
      defaultButtonBackgroundHover: '#2e2e2e',
      //..
    },
  },
};

const SignUp = () => {
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState('');

  React.useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        navigate('/welcome');
      }
    });

    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, [navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setError('You must accept the terms and conditions');
      return;
    }
    setError('');
    // Trigger the Supabase Auth component's sign up process
    document.getElementById('supabase-auth-signup').click();
  };

  const redirectTo = window.location.origin; // Get the current origin

  return (
    <>
      <Navbar navigate={navigate} /> {/* Here is the Navbar component used */}
      <Container component="main" maxWidth="sm">
        <Typography component="h1" variant="h4" gutterBottom>
          Sign Up
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Join us to explore new possibilities.
        </Typography>
        <Box sx={{ mt: 3, width: '100%' }}>
          <form onSubmit={handleSignUp}>
            <Auth
              supabaseClient={supabase}
              providers={['google', 'facebook', 'twitter']}
              appearance={{ theme: customTheme }}
              localization={{
                variables: {
                  sign_in: {
                    social_provider_text: 'Continue with {{provider}}', // Custom text for social sign-in buttons
                  },
                },
              }}
              redirectTo={`${redirectTo}/welcome`} // Explicitly set redirect URL
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  color="primary"
                />
              }
              label="I agree to the terms and conditions"
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 2, bgcolor: 'blue' }} // Custom button color
              id="supabase-auth-signup"
            >
              Sign Up
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default SignUp;
