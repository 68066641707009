import React from 'react';
import { Button, Typography, Container, Box, Stack, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/NavBar';

const ShareholderPage = () => {
    const navigate = useNavigate();

    const handleShare = () => {
        console.log('Share link functionality goes here.');
        // Implement the functionality to share the link
    };

    return (
        <>
            <Navbar />
            <Container maxWidth="lg" sx={{ mt: 8 }}>
                <Box sx={{
                    p: 4,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 'none'
                }}>
                    <Typography variant="h5" gutterBottom component="div">
                        Congratulations, you're a shareholder of Dolly Varden Silver Corp.
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ mt: 2, mb: 2 }}>
                        You have 10,000 shares! For that you'll get:
                    </Typography>
                    <Box sx={{ mb: 4, textAlign: 'left' }}>
                        <Paper elevation={3} sx={{ p: 2, display: 'inline-block', marginLeft: 0 }}>
                            <Typography variant="h3" component="span" color="primary">
                                100
                            </Typography>
                            <Typography variant="subtitle1">
                                Giveaway Entries
                            </Typography>
                        </Paper>
                    </Box>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Invite your friends and when they use this link it'll double your entries.
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                        <Button variant="contained" color="primary" onClick={() => console.log('Share link functionality goes here.')}>
                            Share Giveaway
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => window.location.href = 'https://dolly.orbiton.app'}>
                            Access Shareholder Community
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </>
    );
};

export default ShareholderPage;