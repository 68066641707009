import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import Navbar from '../components/NavBar';
import PlaidLinkButton from '../components/plaidButton';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../util/supabaseClient';

const WelcomePage = () => {
    const [plaidStatus, setPlaidStatus] = useState("Not Connected");
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkUser() {
            const { data: { user }, error } = await supabase.auth.getUser();
            if (error) {
                console.error('Error retrieving user:', error);
            } else if (user) {
                setUser(user);
            }
        }

        checkUser();

        const { data } = supabase.auth.onAuthStateChange((_event, session) => {
            if (session) {
                setUser(session.user);
            } else {
                setUser(null);
            }
        });

        return () => {
            if (data && data.unsubscribe) {
                data.unsubscribe();
            }
        };
    }, []);

    const handleStatusChange = (status, metadata) => {
        setPlaidStatus(status + " - " + JSON.stringify(metadata));
    };

    return (
        <>
            <Navbar />
            <Container>
                <h1>Welcome to the Giveaway!</h1>
                {user ? (
                    <PlaidLinkButton onStatusChange={handleStatusChange} userId={user.id} />
                ) : (
                    <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
                )}
            </Container>
        </>
    );
};

export default WelcomePage;